@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
  width: 100%;
	margin: 0;
	font-family: 'Rubik', sans-serif;
	scrollbar-width: none;
  padding: 0;
  overflow: hidden;
}

body {
  background-color: #191919;
}

#app {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
