.popup {
	bottom: -57%;
	position: absolute;
	background-color: #ffcc00;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	font-size: 12px;
	padding: 5px;
	display: flex;
	width: 200px;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
}

.arrowUp {
	width: 0;
	height: 0;
	content: '';
	left: 11%;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #ffcc00 transparent;
	position: absolute;
	top: -10px;
}

.popupContent {
	text-align: center;
}
