/* map.css */

.custom-marker {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	text-align: center;
	color: white;
	font-size: 12px;
}

.marker-text {
	position: absolute;
	bottom: -20px;
	width: 100%;
	text-align: center;
	font-size: 12px;
	color: white;
}

.user-marker {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: rgb(45, 45, 255);
	border: 1px solid white;
}

.blinking-marker {
	background-color: red; /* Цвет мигающей метки */
	border-radius: 50%;
	width: 12px;
	height: 12px;
	box-shadow: 0 0 10px red; /* Эффект свечения */
	border: 2px solid red; /* Цвет границы */
	animation: blink 1s infinite; /* Анимация мигания */
}

.royal-marker {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: red;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	box-shadow: 0 0 35px red;
	text-align: center;
/* 	animation: blink 1s infinite; */
	color: white;
	font-size: 12px;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.leaflet-popup-content-wrapper {
	background-color: transparent;
	min-height: 110px;
	box-shadow: none !important;
	padding: 0;
}

.leaflet-popup {
	background-color: #191919;
	border-radius: 15px;
	transition: none !important;
	opacity: 1 !important;
}

.leaflet-popup-content-wrapper {
	transition: none !important;
}
.leaflet-popup-tip {
	background-color: #333;
}

.leaflet-popup .leaflet-popup-close-button {
	display: none !important;
}
