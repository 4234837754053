.popup {
	position: absolute;
	bottom: -57%;
	right: 10%;
	background-color: #ffcc00;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	font-size: 12px;
	padding: 5px;
	display: flex;
	width: 120px;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
}

.arrowUp {
	width: 0;
	height: 0;
	content: '';
	right: 14%;
	z-index: 999;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #ffcc00 transparent;
	position: absolute;
	top: -10px;
}

.popupContent {
	text-align: center;
}
