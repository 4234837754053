.popup {
	position: absolute;
	color: black;
	background-color: #ffcc00;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	font-size: 12px;
	top: -80%;
	padding: 6px;
	text-align: center;
	display: flex;
	width: 230px;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
}

.arrowUp {
	width: 0;
	height: 0;
	content: '';
	right: 48%;
	z-index: 999;
	border-width: 5px;
	border-style: solid;
	border-color: #ffcc00 transparent transparent  transparent; 
	position: absolute;
	bottom: -14%;
}

.popupContent {
	text-align: center;
}
